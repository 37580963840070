<template>
  <v-app-bar
    v-scroll="onScroll"
    :color="!isScrolling ? 'transparent' : 'accent'"
    :style="{
      borderBottom: 'medium solid !important',
      borderBottomColor: !isScrolling ? 'transparent' : $vuetify.theme.currentTheme.anchor + ' !important'
    }"
    fixed
    flat
  >
    <v-row no-gutters>
      <v-col
        :cols="$vuetify.breakpoint.mobile ? 7 : 3"
        class="d-flex justify-start"
      >
        <v-slide-x-transition>
          <v-img
            v-if="showLogo"
            :src="$vuetify.breakpoint.mobile ? require('@/assets/logos/logo_mobile.png') : require('@/assets/logos/logo_entreprise.png')"
            class="shrink"
            contain
            height="50"
            :max-width="$vuetify.breakpoint.mobile ? 150 : 200"
          />
        </v-slide-x-transition>
      </v-col>
      <v-col
        v-if="$vuetify.breakpoint.mdAndUp"
        cols="6"
        class="d-flex justify-center"
      >
        <v-slide-y-transition>
          <v-breadcrumbs
            v-if="showLogo"
            :items="navItems"
            divider="|"
          >
            <template v-slot:item="{ item }">
              <li>
                <a
                  :class="'v-breadcrumbs__item' + ((item.href.substring(1) === selectedAnchor) ? ' warning--text' : '')"
                  :href="item.href"
                >
                  {{ item.text }}
                </a>
              </li>
            </template>
          </v-breadcrumbs>
        </v-slide-y-transition>
      </v-col>
      <v-col
        :cols="$vuetify.breakpoint.mobile ? 5 : 3"
        class="d-flex justify-end"
      >
        <social-media />
      </v-col>
    </v-row>
  </v-app-bar>
</template>

<script>
  export default {
    name: 'CoreAppBar',

    components: {
      SocialMedia: () => import('@/components/SocialMedia'),
    },

    data: () => ({
      showLogo: false,
      isScrolling: false,
      navItems: [
        {
          text: 'Accueil',
          href: '#welcome',
        },
        {
          text: 'Réalisations',
          href: '#projects',
        },
        {
          text: 'Services',
          href: '#services',
        },
        {
          text: 'À propos',
          href: '#about',
        },
      ],
    }),
    computed: {
      selectedAnchor: function () {
        return this.$root.$data.anchor
      },
    },
    methods: {
      onScroll () {
        const offset = window.pageYOffset
        this.isScrolling = offset > 50
        this.showLogo = offset > 200
      },
    },
  }
</script>
